import React from 'react';
import cx from 'classnames';
import styles from './ContentCard.module.scss';

const ContentCard = ({ classname, image, size = 'medium', horizontal, children }) => {
  const classNames = cx({
    [styles.root]: true,
    [styles.horizontal]: horizontal,
    [styles[size]]: size && !!styles[size],
  });

  return (
    <div className={cx(classname, classNames)}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url("${image}")`,
        }}
      />
      <div className={styles.content}>
        <div className={styles.wrapper}>
          {children}
        </div>
      </div>
    </div>
  )
};

export default ContentCard;