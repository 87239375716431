import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import 'swiper/swiper.scss';
import CoursesOverview from "../components/coursesOverview";
import Layout from "../containers/layout";
import Hero from "../components/hero";
import ContentCard from '../components/ContentCardTest/ContentCard';
import { Link } from "gatsby"
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SEO from '../components/seo';
import 'swiper/swiper.scss';

SwiperCore.use([Pagination, Autoplay]);

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      site: sanityHomepage(_id: {regex: "/(drafts.|)homepage/"}) {
        hero {
          title
          description
          button
          buttonLink
          buttonSecondary
          buttonSecondaryLink
          backgroundImage {
            asset {
              url
            }
          }
        }
        lessonsOverview {
          title
          lessons {
            title
            button
            buttonLink
            description
            thumbnail {
              asset {
                url
              }
            }
          }
        }
        pageIntroduction {
          subTitle
          title
          description
          showSocials
          buttonText
          buttonLink
          thumbNail {
            asset {
              url
            }
          }
          imageEffect
        }
      }
      reviews: allSanityReview {
        edges {
          node {
            id,
            review,
            auteur
          }
        }
      }
      partners: allSanityPartner {
        edges {
          node {
            id,
            name,
            url,
            thumbnail {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `)
  const { hero, lessonsOverview, pageIntroduction } = data.site;
  const { reviews, partners } = data;
  const scrollToRef = React.useRef(null);

  const scrollToElementRef = React.useCallback(() => {
    if (process.browser) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [])

  return (
    <>
      <Layout>
        <SEO title="Home" />
        <div className="hero__wrapper">
          <Hero data={hero} />
          <div className="index__arrow" onClick={scrollToElementRef} />
        </div>
        <CoursesOverview refRedirect={scrollToRef} data={lessonsOverview} />
        <section className="testimonial">
          <div className="wrap">
            <div className="row center-lg">
              <div className="col-lg-12">
                <div className="partners__title">
                  <h2>
                    Wat andere mensen van de cursussen
                    en workshops vonden:
                  </h2>
                </div>
              </div>
              <Swiper
                spaceBetween={50}
                autoplay={{ delay: 4000 }}
                pagination={{ clickable: true }}
                breakpoints={
                  {
                    786: {
                      slidesPerView: 1,
                      spaceBetweenSlides: 50
                    },
                    920: {
                      slidesPerView: 3,
                      spaceBetweenSlides: 50
                    },
                  }
                }
              >
                {reviews.edges.length > 0 && (
                  <>
                    {reviews.edges.map(({ node }) => (
                      <SwiperSlide key={node.id}>
                        <div className="testimonial__item">
                          <div className="testimonial__icon" />
                          <p className="testimonial__desc">"{`${node.review}`}"</p>
                          <h3 className="testimonial__author">{node.auteur}</h3>
                        </div>
                      </SwiperSlide>
                    ))}
                  </>
                )}
              </Swiper>
            </div>
          </div>
        </section>

        <section className="about-us about-us__home">
          <div className="wrap">
            <div className="row">
              {pageIntroduction?.thumbNail ? (
              <ContentCard horizontal size="medium" image={pageIntroduction.thumbNail.asset.url}>
                <div>
                  <h3>{pageIntroduction.title}</h3>
                  <p>{pageIntroduction.description}</p>
                  {pageIntroduction.showSocials ? (
                    <ul className="socials">
                      <li className="socials__item">
                        <a href="https://www.instagram.com/antjemachen" className="socials__link socials__link--insta"></a>
                      </li>
                      <li className="socials__item">
                        <a href="https://www.facebook.com/Antje-Machen-zwangerschapsadvies-112624847037326/" className="socials__link socials__link--facebook"></a>
                      </li>
                    </ul>
                  ) : pageIntroduction.publishedAt ?
                      (
                        <p>{pageIntroduction.publishedAt}</p>
                      ) :
                      <Link to={pageIntroduction.buttonLink}>
                        <button className='button button--secondary'>{pageIntroduction.buttonText}</button>
                      </Link>
                  }
                </div>
              </ContentCard>
              ): null}
            </div>
          </div>
        </section>
        {partners.edges.length > 0 && (
          <section className="partners">
            <div className="wrap">
              <div className="row center-lg">
                <div className="col-lg-12">
                  <div className="partners__title">
                    <h2>
                      Antje Machen Zwangerschapsadvies
                      werkt samen met:
                  </h2>
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="partners__overview">
                    {partners.edges.map(({ node }) => (
                      <>
                        {node.url && node.thumbnail && (
                          <a key={node.id} href={node.url} className="partners__item">
                            <img src={node.thumbnail.asset.url} className="partners__image" />
                          </a>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    </>
  )
}

export default IndexPage
