import React from "react"
import { Link } from "gatsby"
import ContentCard from './ContentCardTest/ContentCard';

const CoursesOverview = ({ data, refRedirect }) => {
  return (
    <section ref={refRedirect} className={'courses'}>
      <div className="wrap">
        <div className="row">
          <div className="col-lg-12">
            <div className="courses__title">
              <h2>{data.title}</h2>
            </div>
          </div>
          <div className="courses__overview">
            {data.lessons && (
              data.lessons.map(lesson => (
                <>
                  {
                    lesson.thumbnail && (
                      <div className="courses__item">
                        <ContentCard size="small" image={lesson.thumbnail.asset.url}>
                          <div>
                            <h3>{lesson.title}</h3>
                            <p>{lesson.description}</p>
                          </div>
                          <Link className="courses__link" to={lesson.buttonLink}>
                            Lees meer
                          </Link>
                        </ContentCard>
                      </div>
                    )
                  }
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </section >
  )
}

export default CoursesOverview