import React from "react";
import { Link } from "gatsby";


const Hero = ({ data }) => {
  return (
    <section
    className={'index'}
    style={
        {
          background: `url("${data?.backgroundImage ? data.backgroundImage.asset.url : 'https://cdn.sanity.io/images/p5w99mn3/production/85b3e0cc4e0f5353fe60337271faf2bd3653cd48-1440x773.png?w=2000&h=2000&fit=max'}") no-repeat center`,
          backgroundSize: 'cover'
        }
      }
    >
    <div className="wrap">
      <div className="row">
        <div className="index__introduction">
          <h1 className='index__title'>{data.title}</h1>
          <p className='index__paragraph'>{data.description}</p>
          <div className='index__buttons'>
            <Link to={data.buttonLink}>
              <button className={'button'}>{data.button}</button>
            </Link>
              {data.buttonSecondaryLink && (
                <Link to={data.buttonSecondaryLink}>
                  <button className={'button button--secondary'}>{data.buttonSecondary}</button>
                </Link>
              )}
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}

export default Hero;